<template>
    <div class="box">
            <p>暂无登记信息，请先登记</p>
        <div class="btn-box">
            <van-button type="primary" @click="doRegister" round block>我要登记
            </van-button>
        </div>
    </div>
</template>

<script>
    import {getUrlParam} from "../../libs/kit";
    import { Button,} from 'vant';
    export default {
        name: "index",
        components: {
            [Button.name]: Button,
        },
        data(){
            return {
                userId:'',
            }
        },
        created(){
            let code = getUrlParam("code");
            let openId = this.$store.state.openId;
            if (code) {
                if (!openId) {
                    this.$post('/tourist/getOpenId', {code}).then(res => {
                        this.$store.dispatch('saveToken', res.object.token);
                        this.$store.dispatch('saveOpenId', res.object.openId);
                        this.userId=res.object.id
                    })
                }else {
                    this.$post('/tourist/getUserId', {openId}).then(res => {
                        this.userId = res.object;
                    })
                }
            }else {
                this.$post('/tourist/getUserId', {openId}).then(res => {
                    this.userId = res.object;
                })
            }
        },
        computed: {
            scenicId(){
                return this.$store.state.scenicId;
            },
            openId(){
                return this.$store.state.openId;
            },
            token(){
                return this.$store.state.token;
            }
        },
        methods:{
            doRegister(){
                // this.$router.push({path: '/tourist',query:{traffic:this.traffic,carInfo:this.carInfo}});
                this.$router.push({path: '/tourist',query:{traffic:0,carInfo:''}});
                // this.$router.replace({path: '/process/traffic/'});
            },
            doSuccess(){
                this.$router.replace({path: '/tourist/success/' + this.userId+"/"+this.$store.state.scenicId});
            }
        }
    }
</script>

<style lang="scss" scoped>
    .box {
        min-height: 100vh;
        display: flex;
        background-color: #fff;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        p{
            margin: 5vw 0 0;
            font-size:6vw;
        }

    }
    .btn-box {
        margin-top: 15vw;
        button{
            margin-bottom: 5vw;
            width: 50vw;
        }
    }

</style>
